<template>
  <div class="SubTitle">
    <span class="title"><slot/></span>
  </div>
</template>

<script>
export default {
  name: "SubTitle",
};
</script>

<style lang="less" scoped>
.SubTitle {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title{
    display: flex;
    align-items: center;
    &::before{
      content: '';
      width: 4px;
      height: 14px;
      background: #6589F8;
      margin-right: 4px;
      border-radius: 4px;
    }
  }
  .des {
    flex: 1;
    margin-left: 12px;
    font-size: 12px;
    color: #666;
  }

  .dashed {
    height: 1px;
    margin: 16px;
    flex: 1;
    border-bottom: 1px dashed @border-color-base;
  }
}
</style>
