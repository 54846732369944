<template>
  <div class="search">
    <div class="search-l">
      <el-input
        v-model="formData.keyword"
        :placeholder="placeholderText"
        clearable
        size="small"
        class="input-search"
      >
        <el-button
          slot="append"
          size="small"
          type="primary"
          @click="handleQuery"
          >搜索</el-button
        >
      </el-input>
      <slot name="left"></slot>
    </div>
    <div class="search-r">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  nmae: "Search",
  props: {
    formData: Object,
    placeholderText: {
      type: String,
      default: "联盟名称/联系人/联系电话",
    },
  },
  methods: {
    handleQuery() {
      this.$emit("handleQuery");
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  padding: 10px;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search-l {
    display: flex;
    align-items: center;
    flex: 1;
    .input-search {
      max-width: 300px;
      min-width: 250px;
      margin-right: 10px;
    }
    /deep/.el-input-group__append button.el-button {
      border-color: #6589f8;
      background-color: #6589f8;
      color: #fff;
    }
  }
}
</style>
