import request, { SERVICE_MODULE_MAP } from "@/utils/request";

//创建联盟
export function createAlliance(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/alliance",
    method: "post",
    data,
  });
}

//获取公司列表
export function postCompanyList(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/companies/list",
    method: "post",
    data,
  });
}

//更新联盟
export function updateAlliance(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/${data.id}`,
    method: "post",
    data,
  });
}

//我加入的联盟列表
export function getAllianceMyList(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/my`,
    method: "get",
    params,
  });
}

// 联盟相关统计信息
export function getAllianceCounts(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/counts`,
    method: "get",
    params,
  });
}

//所有联盟列表
export function getAllianceList(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance`,
    method: "get",
    params,
  });
}

//联盟详情
export function getAllianceDetail(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/${params.id}`,
    method: "get",
  });
}

//解散联盟
export function deleteAlliance(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/${data.id}`,
    method: "delete",
  });
}

//申请加盟
export function applyAlliance(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/members/apply/${data.id}`,
    method: "post",
  });
}

//邀请加盟
export function inviteAlliance(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/members/invite/${data.id}`,
    method: "post",
    data: { companyIds: data.companyIds },
  });
}

//启用
export function enableAlliance(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/members/${data.id}/enable`,
    method: "post",
  });
}

//停用
export function disableAlliance(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/members/${data.id}/disable`,
    method: "post",
  });
}

//联盟成员列表
export function getAllianceMembersList(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/members/${params.id}`,
    method: "get",
    params,
  });
}

//待审核列表
export function getAllianceToaduitList(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/members/to-audit`,
    method: "get",
    params,
  });
}

//已拒绝/已驳回列表
export function getAllianceFailedList(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/members/failed`,
    method: "get",
    params,
  });
}

//加盟申请审核
export function applyAllianceAudit(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/members/apply/${data.id}/audit`,
    method: "post",
    data: data.params,
  });
}

//审核加盟邀请
export function inviteAllianceAudit(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/members/invite/${data.id}/audit`,
    method: "post",
    data: data.params,
  });
}

//踢出联盟成员
export function deleteAllianceMembers(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/members/${data.id}`,
    method: "delete",
    data: {
      ids: data.ids,
    },
  });
}

//退出联盟
export function quitAllianceMembers(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/members/${data.id}/quit`,
    method: "post",
    data,
  });
}

//移交盟主
export function transferAlliance(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/members/${data.allianceId}/transfer/${data.memberId}`,
    method: "post",
  });
}

//日志列表
export function getAllianceLogs(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: `/alliance/logs/${params.id}`,
    method: "get",
    params,
  });
}
